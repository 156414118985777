import Image from 'next/image';
import { Modal } from 'react-bootstrap';
import Link from 'next/link';

import closebtn from '@/images/closebtn.svg';

const WelcomeModal = ({
  showAutofillPopup,
  handleWelcomeModalClose,
  siteInfo,
  setShowAutofillPopup,
}) => {
  return (
    <Modal
      centered
      show={showAutofillPopup}
      onHide={handleWelcomeModalClose}
      className="welcomeautofill-popup"
    >
      <span className="closebtn" onClick={handleWelcomeModalClose}>
        <Image alt="closebtn" src={closebtn} />
      </span>
      <Modal.Body>
        <>
          <h4>
            Welcome to
            <br />
            {siteInfo.site_name}!
          </h4>
          <p>
            Enter your info to auto-fill templates <br />
            or jump right into exploring
          </p>
          <div className="link-buttons">
            <Link href="/autofill">
              <a
                className="button-outlined"
                onClick={() => setShowAutofillPopup(false)}
              >
                <span>Add Autofill</span>
              </a>
            </Link>
            <Link href="/holidays-graphics">
              <a
                className="button-contained"
                onClick={() => setShowAutofillPopup(false)}
              >
                Explore Templates
              </a>
            </Link>
          </div>
        </>
      </Modal.Body>
    </Modal>
  );
};

export default WelcomeModal;
